var ninMain = function() {
    var logincheck_timeout = null;
    
    var initLogincheck = function() {
        window.clearTimeout(logincheck_timeout);
        logincheck_timeout = window.setTimeout(function(){
            checkLogin();
        }, 5*60*1000); 
    }
    var checkLogin = function() {
        window.clearTimeout(logincheck_timeout);
        logincheck_timeout = window.setTimeout(function(){
            $.ajax({
                type: "POST",
                url: '/async/checklogin',
                success: function(content) {
                    if(content.match('NOTOK')){
                        location.reload();
                    } else {
                        checkLogin();
                    }
                }
            });
        }, 5*60*1000);
    }
   
    /********* PUBLIC *********************************************************/
    return {
        init: function() {
            if(initview == 'home'){
                ninGui.initHome();
            }
            if(initview == 'product'){
                ninGui.init();
                ninForms.init();
                ninContent.init();
                ninMaps.init();
                initLogincheck();
            }
        }
    }
}();

$(function() {    
   ninMain.init(); 
});
