var ninForms = function() {
    var handleForms = function() {

        var handleInput = function(el) {
            if (el.val() != "") {
                el.addClass('edited');
            } else {
                el.removeClass('edited');
            }
        } 
        var handleErrors = function(el) {
            if (el.hasClass('dvForm_missed')) {
                el.parent('.form-group').addClass('has-error');
            } else if (el.find('input').hasClass('dvForm_missed')){
                el.parent('.form-group').addClass('has-error');
            }
        } 

        $('body').on('keydown', '.nin-text', function(e) { 
            handleInput($(this));
        });
        $('body').on('blur', '.nin-text, .nin-select', function(e) { 
            handleInput($(this));
        });
        $('body').on('select', '.nin-select', function(e) { 
            handleInput($(this));
        });
        $('.nin-text, .nin-select').each(function(){
            handleInput($(this));
        });
        $('.nin-radio label').on('click').each(function(){
            handleInput($(this));
        });
        /// init for errors
        $('.nin-text, .nin-select, .nin-checkbox, .nin-radio').each(function(){
            handleErrors($(this))
        });

        $('body').on('click', '.nin-checkbox > label, .nin-radio > label', function() {
            var the = $(this);
            var el = $(this).children('span:first-child');
            el.addClass('inc');
            var newone = el.clone(true);  
            el.before(newone);  
            $("." + el.attr("class") + ":last", the).remove();
        }); 
    
        $('.submit-enter').keyup(function (e){
            var keycode;
            if (window.event) keycode = window.event.keyCode;
            else if (e) keycode = e.which;
            else return true;
            if (keycode === 13){
               $(this).parents('form').submit();
               return false;
            } else {
               return true;
            }
        });
    }

    return {
        init: function() {
            handleForms();
        },
        forms: function() {
            handleForms();
        }
    }
}();