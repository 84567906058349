var ninContent = function() {
    
    var loadContent = function(setstate) {
        if(!setstate) setstate=2;
        $('#map-layer').hide('fast');
        var content_container = $('#content');
        content_container.html('<div class="loading"><img src="' + loader + '" /></div>');
        var cont_id = content_container.attr('data-content-id');
        var cont_layout = parseInt(content_container.attr('data-layout'));
        if(setstate > 1){
            //console.log('set pushState: ' + cont_id);
            window.history.pushState({idc: cont_id}, "NIN ONLINE", window.location);
        } else if(setstate > 0){
            //console.log('set replaceState: ' + cont_id);
            window.history.replaceState({idc: cont_id}, "NIN ONLINE", window.location);
        }


        $.ajax({
            type: "POST",
            url: '/async/getcontent',
            data: '&i=' + cont_id,
            success: function(content) {
                var json_data = $.parseJSON(content);
                if(json_data['update']){
                    content_container.html('<div class="contentupdate">' + json_data['update'] + '</div>')
                } else {
                    $('#content-title').html(json_data['title']);
                    if(json_data['lastview']){
                        $('#back-global-button').show().attr('href',json_data['lastview']);
                    } else {
                        $('#back-global-button').hide();
                    }

                    var keyword = decodeURIComponent(content_container.attr('data-keyword'));
                    var ret_content = '';

                    // highlight keywords
                    if(typeof keyword !== typeof undefined && keyword !== false && keyword != ''){
                        var searchstring = keyword.replace(/\s(?=(?:"[^"]*"|[^"])*$)/gm,'|');
                        var searchstring2 = searchstring.replace(/\|$|\"/gm,'');
                        var searcharray = searchstring.split("|");
                        newsearcharray = searcharray.filter(function(item) {
                            return item.length > 1
                        })
                        var searchstring3 = newsearcharray.join("|");
                        var selectedTextRegExp = new RegExp(searchstring3,"gi");

                        var cont = json_data['content'];
                        var keycnt = 0;
                        
                        $.each(cont, function(key, value){
                            if(value['cnt'] !== 'null' && value['cnt'] !== null && value['cnt'] != ''){
                                value['cnt'] = value['cnt'].replace(selectedTextRegExp, function (match, pos, original) {
                                    keycnt++;
                                    return '<mark class="searchresult" data-id="' + cont_id + '-' + keycnt + '">' + match + '</mark>';
                                });
                            }
                            var contentbox = ninTemplates.contentbox(key,value,cont_layout);
                            ret_content += contentbox;
                        });
                    } else {
                        content_container.html('');
                        var cont = json_data['content'];
                        $.each(cont, function(key, value){
                            var contentbox = ninTemplates.contentbox(key,value,cont_layout);
                            ret_content += contentbox;
                        });
                    } 
                    content_container.html('');
                    content_container.append(ret_content);
                    if(cont_layout > 5){
                        $('.betoggler').hide(0);
                    } else {
                        if($('.betoggler').hasClass('selected')){
                            $('.content-inner').addClass('be-show');
                            $('.betoggler-single').addClass('selected');
                        }
                    }

                    ninNotation.markings();
                    ninGui.initAfterSync();
                    initPrint();
                    showhideActions();
                    // initialisations of events after highlighting
                }
                
                ga('set', { page: '/async/getcontent/', title: 'Inhalte asynchron' });
                ga('send', 'pageview');

            }
        });
    }
    
    
    var showhideActions = function() {
        var content_id = $('body.product #content').attr('data-content-id');
        if(content_id == 'init'){
            $('body.product .portlet-title .actions').hide(0);
        } else {
            $('body.product .portlet-title .actions').show(200);
        }
    }

    
    var loadStructure = function() {
        var structure_container = $('.dock-structure .cont');
        structure_container.html('<div class="loading"><img src="' + loader + '" /></div>');
        $.ajax({
            type: "POST",
            url: '/async/getstructure',
            success: function(content) {
                var json_data = $.parseJSON(content);
                if(json_data['update']){
                    structure_container.html('<div class="dockupdate">' + json_data['update'] + '</div>')
                } else {
                    structure_container.html(json_data['structure']);
                    ninGui.initAfterSync();
                }
                ga('set', { page: '/async/getstructure/', title: 'Inhaltsverzeichnis asynchron' });
                ga('send', 'pageview');
            }
        });
    }

    var loadKeywords = function() {
        var keywords_container = $('.dock-keywords .cont');
        keywords_container.html('<div class="loading"><img src="' + loader + '" /></div>');
        $.ajax({
            type: "POST",
            url: '/async/getkeywords',
            success: function(content) {
                keywords_container.html('');

                var json_data = $.parseJSON(content);
                if(json_data['update']){
                    keywords_container.html('<div class="dockupdate">' + json_data['update'] + '</div>')
                } else {
                    var result = json_data['keywords'];
                    if(result.length > 0){

                        var resultslist = $('<ul class="keywords-result-list"></ul>');
                        $.each(result, function(key, value){
                            var keyword = $('<li></li>');
                            keyword.append('<a class="toggle-next"><i><svg class="arrow"><use class="arrow-use" xlink:href="/dist/gui.svg#arrow-r"></use></svg></i><strong>' + value['key'] + '</strong></a>');
                            if(value['chp'].length > 0){
                                var chapterslist = $('<ul></ul>');
                                $.each(value['chp'], function(key1, value1){
                                    var chaptersresult = ninTemplates.keywordschapter(key1,value1);
                                    chapterslist.append(chaptersresult);
                                });
                                keyword.append(chapterslist);
                            }
                            resultslist.append(keyword);
                        });
                        keywords_container.append(resultslist);
                    }
                    ninGui.initAfterSync();
                }
                
                ga('set', { page: '/async/getkeywords/', title: 'Schlagworte asynchron' });
                ga('send', 'pageview');
            }
        });
    }
    

    var loadNotations = function() {
        var notations_container = $('.dock-notations .cont');
        notations_container.html('<div class="loading"><img src="' + loader + '" /></div>');
        $.ajax({
            type: "POST",
            url: '/async/getnotations',
            success: function(content) {
                notations_container.html('');

                var json_data = $.parseJSON(content);
                if(json_data['update']){
                    notations_container.html('<div class="dockupdate">' + json_data['update'] + '</div>')
                } else {
                    var result = json_data['notations'];
                    //if(result.length > 0){ // todo check if object

                        var resultslist = $('<ul class="notations-result-list"></ul>');
                        $.each(result, function(key, value){
                            var ch = value['chp'];
                            var chapter = $('<li></li>');
                            chapter.append('<a class="internal-link chapter" data-content-id="' + ch['fkc'] + '"><span>' + ch['dis'] + '</span><strong>' + ch['tit'] + '</strong></a>');

                            if(typeof value['nts'] !== typeof undefined && value['nts'] !== false && value['nts'] != ''){
                                if(value['nts'].length > 0){
                                    var notationslist = $('<ul></ul>');
                                    $.each(value['nts'], function(key1, value1){
                                        var notationsresult = ninTemplates.notation(ch['fkc'],value1);
                                        notationslist.append(notationsresult);
                                    });
                                    chapter.append(notationslist);
                                }
                            }
                            resultslist.append(chapter);
                        });
                        notations_container.append(resultslist);
                    //}

                    ninGui.initAfterSync();
                }
                
                ga('set', { page: '/async/getnotations/', title: 'Notizen asynchron' });
                ga('send', 'pageview');
            }
        });
    }

    var searchContent = function() {
        var search_container = $('.dock-search .cont');
        
        if($('.dock-search #src').val().match(/^\d+(\.\d+){1,8}$/)){
            //search_container.html('<div class="loading"><img src="' + loader + '" /></div>');
            $.ajax({
                type: "POST",
                url: '/async/getbychapternumer',
                data: '&s=' + $('.dock-search #src').val(),
                success: function(idc) {
                    if(idc > 0){
                        var content = $('#content');
                        content.attr('data-content-id',idc);
                        content.attr('data-keyword','');
                        loadContent(1);
                    }
                }
            });

        } else {
            var src = encodeURIComponent($('.dock-search #src').val());
            //console.log('Search-Field: ' + src);
            search_container.html('<div class="loading"><img src="' + loader + '" /></div>');
            $.ajax({
                type: "POST",
                url: '/async/getsearch',
                data: '&s=' + src,
                success: function(content) {
                    search_container.html('');

                    var json_data = $.parseJSON(content);
                    if(json_data['update']){
                        search_container.html('<div class="dockupdate">' + json_data['update'] + '</div>')
                    } else {
                        var string = json_data['search'];
                        var total = json_data['total'];
                        var totalcontainer = $('<div class="search-result-total"></div>').html('Total: ' + total);
                        search_container.append(totalcontainer);

                        var result = json_data['content'];
                        if(result.length > 0 && total > 0){
                            var projectcontainer = $('<div class="search-result-project"></div>');
                            $.each(result, function(key, value){
                                var projecttitle =  $('<h3 class="toggle-next"><svg class="arrow"><use xlink:href="/dist/gui.svg#arrow-d"></use></svg>' + value.title + '</h3>');
                                projectcontainer.append(projecttitle);

                                var resultslist = $('<ul class="search-result-list"></ul>');
                                var cnt = 1;
                                $.each(value.list, function(key, value){
                                    var searchresult = ninTemplates.searchresult(key,value,cnt);
                                    resultslist.append(searchresult);
                                    cnt++;
                                });
                                projectcontainer.append(resultslist);
                            });
                            search_container.append(projectcontainer);
                        }
                        ninGui.initSearchnav();
                        ninGui.initAfterSync();
                    }

                    ga('set', { page: '/async/getsearch/', title: 'Suche asynchron' });
                    ga('send', 'pageview');

                }
            });
        }
    }


    var searchGlobal = function() {
        var searchglobal_container = $('#search-global-result');
        var src = encodeURI($('#search-global #srcglob').val());
        searchglobal_container.html('<div class="loading"><img src="' + loader + '" /></div>');
        
        $.ajax({
            type: "POST",
            url: '/async/getsearchglobal',
            data: '&s=' + src,
            success: function(content) {
                searchglobal_container.html('');

                var json_data = $.parseJSON(content);
                var result = json_data['content'];
                if(result.length > 0){
                    var productcontainer = $('<div class="search-result-product"></div>');
                    $.each(result, function(key, value){
                        var producttitle =  $('<h3 class="toggle-next"><svg class="arrow"><use xlink:href="/dist/gui.svg#arrow-r"></use></svg>' + value.title + '</h3>');
                        productcontainer.append(producttitle);
                        
                        var projectcontainer = $('<div class="search-result-project"></div>');
                        $.each(value.projs, function(key2, value2){
                            var projecttitle =  $('<h4 class="toggle-next"><svg class="arrow"><use xlink:href="/dist/gui.svg#arrow-r"></use></svg>' + value2.title + '</h4>');
                            projectcontainer.append(projecttitle);
                            var resultslist = $('<ul class="search-result-list"></ul>');
                            $.each(value2.list, function(key3, value3){
                                var searchresult = ninTemplates.searchresultglob(key3,value3);
                                resultslist.append(searchresult);
                            });
                            projectcontainer.append(resultslist);
                        });
                        productcontainer.append(projectcontainer);
                    });
                    searchglobal_container.append(productcontainer);
                }
                ga('set', { page: '/async/getsearchglobal/', title: 'Globale Suche asynchron' });
                ga('send', 'pageview');

                ninGui.initAfterSync();
            }
        });
                
        
    }
    
    var initBEToggler = function() {
        $('.betoggler').each(function(){
            if(!$(this).hasClass('has-betoggler')){
                $(this).addClass('has-betoggler');
                $(this).click(function(){
                    var betogglerstatus = 0;
                    var contents = $('.content-inner');
                    var contents_single = $('.betoggler-single');
                    $('.content-inner').removeClass('be-show-inner,be-hide-inner');
                    
                    if($(this).hasClass('selected')){
                        contents.removeClass('be-show');
                        contents_single.removeClass('selected');
                        $(this).removeClass('selected');
                    } else {
                        betogglerstatus = 1;
                        contents.addClass('be-show');
                        contents_single.addClass('selected');
                        $(this).addClass('selected');
                    }
                    $.ajax({
                        type: "POST",
                        url: '/async/setsession',
                        data: '&param=betoggler&val=' + betogglerstatus
                    });
                });
            }
        });
        $('.betoggler-single').each(function(){
            //if(!$(this).hasClass('has-betoggler-single')){
                //$(this).addClass('has-betoggler-single');
                $(this).click(function(){
                    var be_part = $(this).parent().next();
                    if(be_part.hasClass('be-show')){
                        be_part.removeClass('be-show');
                        $(this).removeClass('selected');
                    } else {
                        be_part.addClass('be-show');
                        $(this).addClass('selected');
                    }
                });
            //}
        });
    }
    var initPrint = function() {
        var cont_id = $('#content').attr('data-chapter-id');
        $('.print-button').attr('href','/action/print/chapter/' + cont_id);
    }
    var initContentLanguage = function() {
        $('.lang-button').each(function(){
            if(!$(this).hasClass('has-function')){
                $(this).addClass('has-function')
                $(this).click(function(){
                    var selected_lang = $(this).attr('data-lang');
                    $('.lang-button').removeClass('selected');
                    $.ajax({
                        type: "POST",
                        url: '/async/setsession',
                        data: '&param=selected_content_language&val=' + selected_lang
                    });
                    $(this).addClass('selected');
                    loadContent(1);
                });
            }
        });
    }

    var scrolltoMarking = function() {
        var content = $('#content');
        var notation_set = content.attr('data-marking-id');
        if(typeof notation_set !== typeof undefined && notation_set !== false && notation_set != ''){
            var target_notations = $('mark[data-id=' + notation_set + ']');
            if(typeof target_notations !== typeof undefined &&  target_notations.length){
                var scrolltop = target_notations.offset().top - content.offset().top + content.scrollTop();
                content.animate({
                    scrollTop: scrolltop
                }, 500);
            }
        }
    }

    return {
        init: function() {
            loadStructure();
            loadContent(1);
            loadKeywords();
            loadNotations();
            initContentLanguage();
        },
        load: function(setstate) {
            loadContent(setstate);
        },
        search: function() {
            searchContent();
        },
        searchglobal: function() {
            searchGlobal();
        },
        notations: function() {
            loadNotations();
        },
        print: function() {
            initPrint();
        },
        afterhighlighting: function() {
            initBEToggler();
            scrolltoMarking();
            ninGui.initAfterSync();
        }

        
    }
}();