var ninMaps = function() {
    
    var ll_map = null;
    
    var pulseIcon0  = L.icon({ iconUrl:'/dist/marker00.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon1  = L.icon({ iconUrl:'/dist/marker01.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon2  = L.icon({ iconUrl:'/dist/marker02.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon3  = L.icon({ iconUrl:'/dist/marker03.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon4  = L.icon({ iconUrl:'/dist/marker04.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon5  = L.icon({ iconUrl:'/dist/marker05.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon6  = L.icon({ iconUrl:'/dist/marker06.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon7  = L.icon({ iconUrl:'/dist/marker07.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon8  = L.icon({ iconUrl:'/dist/marker08.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon9  = L.icon({ iconUrl:'/dist/marker09.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    var pulseIcon99 = L.icon({ iconUrl:'/dist/marker99.gif', iconSize:[50, 50], iconAnchor:[25, 25] });
    
    var markergroup0 = [];
    var markergroup1 = [];
    var markergroup2 = [];
    var markergroup3 = [];

    var markerlevel0 = null;
    var markerlevel1 = null;
    var markerlevel2 = null;
    var markerlevel3 = null;

    var showMap = function(nr,ly,bounds,scale) {
        
        if (ll_map != null) {
            ll_map.remove();
            ll_map = null;
        }
        var bnds = eval(bounds);
        ll_map = L.map('map-container', { 
            crs: L.CRS.Simple,
            zoomControl: false
        });
        
        L.imageOverlay('/maps/' + nr + '/' + ly, bnds).addTo(ll_map);
        L.control.zoom({ position:'topright'}).addTo(ll_map);
        ll_map.setMaxBounds(bnds);   
        ll_map.fitBounds(bnds);   
        ll_map.setZoom(scale-3);   
        
        ////////////////////////////////////////////////////////////////////////
        // pois ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////
        
        
        $.ajax({
            type: "POST",
            url: '/async/getmapspois',
            data: '&n=' + nr + '&l=' + ly,
            success: function(content) {
                markergroup0 = [];
                markergroup1 = [];
                markergroup2 = [];
                markergroup3 = [];
                
                var markers = $.parseJSON(content);

                
                for ( var i=0; i < markers.length; ++i ) {
                    if(markers[i].ext_link != ''){
                        if(markers[i].ext_link.match('##int##')){
                            eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openInternalLink, 'null', markers[i].ext_link)).bindTooltip(markers[i].title + ' <svg class="maps-tooltip-icon"><use xlink:href="/dist/gui.svg#external-link"></use></svg>', { offset: L.point(0, 25),direction:'center' }));            
                        } else {
                            eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openExternalLink, 'null', markers[i].ext_link)).bindTooltip(markers[i].title + ' <svg class="maps-tooltip-icon"><use xlink:href="/dist/gui.svg#external-link"></use></svg>', { offset: L.point(0, 25),direction:'center' }));            
                        }
                    } else if(markers[i].download != ''){
                        eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openExternalLink, 'null', markers[i].download)).bindTooltip(markers[i].title + ' <svg class="maps-tooltip-icon"><use xlink:href="/dist/gui.svg#download"></use></svg>', { offset: L.point(0, 25),direction:'center' }));            
                    } else {
                        eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(showMapDetail, 'null', markers[i].title, markers[i].text)).bindTooltip(markers[i].title, { offset: L.point(0, 25),direction:'center' }));
                    }
                    /*
                    if(markers[i].ext_link != ''){
                        eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(openExternalLink, 'null', markers[i].ext_link)).bindTooltip(markers[i].title, { offset: L.point(0, 25),direction:'center' }));            
                    } else {
                        eval('markergroup' + markers[i].level).push(L.marker([markers[i].lat/scale, markers[i].lng/scale], {icon: eval('pulseIcon' + markers[i].icon)}).on('click', L.bind(showMapDetail, 'null', markers[i].title, markers[i].text)).bindTooltip(markers[i].title, { offset: L.point(0, 25),direction:'center' }));
                    }*/
                }
                markerlevel0 = L.layerGroup(markergroup0).addTo(ll_map);
                markerlevel1 = L.layerGroup(markergroup1);
                markerlevel2 = L.layerGroup(markergroup2);
                markerlevel3 = L.layerGroup(markergroup3);
            }
        });

        ll_map.on('zoomend', function() {
            var currentZoom = ll_map.getZoom();
            if (currentZoom > 2){ ll_map.addLayer(markerlevel1); } else { ll_map.removeLayer(markerlevel1); }
            if (currentZoom > 3){ ll_map.addLayer(markerlevel2); } else { ll_map.removeLayer(markerlevel2); }
            if (currentZoom > 4){ ll_map.addLayer(markerlevel3); } else { ll_map.removeLayer(markerlevel3); }
        });        
    }
   
   
    var initMaps = function() {
        $('.map-preview').each(function(){
            if(!$(this).hasClass('has-showmap')){
                $(this).click(function(){
                    var nr = $(this).attr('data-nr');
                    var layer = $(this).attr('data-layer');
                    var bounds = $(this).attr('data-bounds');
                    var scale = $(this).attr('data-scale');
                    
                    var map_layer = $('#map-layer');
                    var map_container = $('#map-container');
                    var current_map_nr = map_container.attr('data-nr')
                    var current_map_layer = map_container.attr('data-layer')
                    
                    if(parseInt(current_map_nr) != parseInt(nr) || parseInt(current_map_layer) != parseInt(layer)){
                        map_container.attr('data-nr',nr).attr('data-layer',layer);
                        map_layer.show('fast', function(){
                            showMap(nr,layer,bounds,scale);
                        });
                    } else if (map_container.is(':visible')){
                        map_layer.hide('fast');
                    } else {
                        map_layer.show('fast');                        
                    }
                });
                $(this).addClass('has-showmap');
            }
        });
        $('.map-close').each(function(){
            if(!$(this).hasClass('has-close')){
                $(this).click(function(){
                    $('#map-layer').hide('fast');
                });
                $(this).addClass('has-close');
            }
        });
    }
    
    var showMapDetail = function(param1,param2){
        $('#maps-overlay-inner').html(param2)
        showOverlay();
    }
    
    var openExternalLink = function(param1){
        window.open(param1);
    }

    var openInternalLink = function(param1){
        location.href = param1.replace('##int##','');
    }
    return {
        init: function() {
            initMaps();
        },
    }
}();