var last_dis = '';

var ninTemplates = function() {
    
    var contentbox = function(id, cont, lyt) {
        var be_class = '';
        var teconia_class = '';
        var title_class = '';
        var istoc_class = '';
        var ret = '';
        
        ret += '<div id="content-box-' + id + '" class="content-box">';
        
        if((lyt == 5 || lyt == 15) && cont['id'] > 0){
            ret += '<div class="content-left">';
            var toc_class = '';
            if(parseInt(cont['toc']) < 1){
                toc_class = ' sub-right';
            }

            if(parseInt(cont['be']) < 1 || cont['be'] == null){
                if(cont['dis'] != null){
                    switch(parseInt(cont['hie'])){
                        case 1:
                            ret += '<strong class="content-nr h1' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 2:
                            ret += '<strong class="content-nr h2' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 3:
                            ret += '<strong class="content-nr h3' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 4:
                            ret += '<strong class="content-nr h4' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 5:
                            ret += '<strong class="content-nr h5' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 6:
                            ret += '<strong class="content-nr h6' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 7:
                            ret += '<strong class="content-nr h7' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                    }
                    last_dis = cont['dis'];
                }
            } else {
                if(cont['dis'] != null && cont['dis'] != last_dis && (cont['tit'] == 'null' || cont['tit'] == null || cont['tit'] == '')){
                    switch(parseInt(cont['hie'])){
                        case 1:
                            ret += '<strong class="content-nr h1' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 2:
                            ret += '<strong class="content-nr h2' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 3:
                            ret += '<strong class="content-nr h3' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 4:
                            ret += '<strong class="content-nr h4' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 5:
                            ret += '<strong class="content-nr h5' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 6:
                            ret += '<strong class="content-nr h6' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                        case 7:
                            ret += '<strong class="content-nr h7' + toc_class + '">' + cont['dis'] + '</strong>';
                        break;
                    }
                }
                
                
                ret += '<strong class="content-nr betoggler-single"><svg><use xlink:href="/dist/gui.svg#be-' + cont['lan'] + '"></use></svg></strong>';
                be_class = ' is-be';
            }
            var newitem = '';
            if(cont['cls'] != ''){
                if(cont['cls'].match('new')){
                    ret += '<svg class="newchapter"><use xlink:href="/dist/gui.svg#new"></use></svg>';
                }
            }

            ret += '</div>';
        }
        
        if(parseInt(cont['itc']) > 0){
            istoc_class = ' is-toc';
        }
        if(cont['cls'] != ''){
            teconia_class = ' ' + cont['cls'];
            if(cont['cls'].match('titlepage|noshow|coversheet|last-page')){
                title_class = ' notitle';
            }
        }

        ret += '<div class="content-inner' + be_class + istoc_class + title_class + '" data-content-id="' + cont['id'] + '">';
        
        if(cont['tit'] != 'null' && cont['tit'] != null && cont['tit'] != ''){
            var dis = '';
            if(cont['dis'] != null && cont['dis'] != ''){
                dis = '<span class="chapternumber">'+ cont['dis'] + '</span>';
            }

            switch(parseInt(cont['hie'])){
                case 1:
                    ret += '<h1 id="chapter' + cont['id'] + '">'+ cont['tit'] + '</h1>';
                break;
                case 2:
                    ret += '<h2 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h2>';
                break;
                case 3:
                    ret += '<h3 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h3>';
                break;
                case 4:
                    ret += '<h4 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h4>';
                break;
                case 5:
                    ret += '<h5 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h5>';
                break;
                case 6:
                    ret += '<h6 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h6>';
                break;
                case 7:
                    ret += '<h7 id="chapter' + cont['id'] + '">'+ dis + '<span class="chaptertitle">' + cont['tit'] + '</span></h7>';
                break;
            }
        }
        if(cont['bl'] !== 'null' && cont['bl'] !== null && cont['bl'] != ''){
            ret += '<div class="backlinks">' + cont['bl'] + '</div>';
        }
        if(cont['cnt'] !== 'null' && cont['cnt'] !== null && cont['cnt'] != ''){
            if(lyt == 20 || lyt == 19 || lyt == 17){
                ret += '<div class="content' + teconia_class + '">' + cont['cnt'] + '</div>';
            } else {
                ret += cont['cnt'];
            }
        }

        
        ret += '</div>';
        
        ret += '</div>';
        
        return ret;
    }
    var searchresult = function(id, cont, cnt) {
        var ret = $('<li class="search-result-item internal-link searchresult-link" data-content-id="' + cont['fkc'] + '" data-keyword="' + cont['key'] + '" data-marking="' + cont['fkc'] + '-' + cnt + '"></div>');
        if(cont['dbl'] < 1){
            var ret_title = $('<strong>'+ cont['dis'] + ' - '+ cont['tit'] + '</strong>');
        }
        var ret_preview = $('<p>'+ cont['prv'] + '</p>');

        ret.append(ret_title).append(ret_preview);

        return ret;
    }
    var searchresultglobal = function(id, cont) {
        var ret = $('<li class="search-result-item"></div>');
        if(cont['dbl'] < 1){
            var ret_title = $('<a href="/show/' + cont['prd'] + '/'+ cont['trg'] + '"><strong>'+ cont['dis'] + ' - '+ cont['tit'] + '</strong></a>');
        }
        var ret_preview = $('<p>'+ cont['prv'] + '</p>');

        ret.append(ret_title).append(ret_preview);

        return ret;
    }
    var keywordschapter = function(id, cont) {
        var ret = $('<li><a class="internal-link" data-content-id="' + cont['fkc'] + '" data-keyword="' + cont['key'] + '"><span>' + cont['dis'] + '</span><strong>' + cont['tit'] + '</strong></a></li>');
        return ret;
    }
    var notation = function(fkc, cont) {
        var note = $('<li></li>');
        //var textonly = cont['mrk'].replace(/<\/?[^>]+(>|$)/g, "");
        //var textonly = cont['mrk'].replace(/(<(\w+)(?![^>]*class="ninchar")[^>]*>)([^<]*)<\/\2>|(?:<br>|<br\/>)/g, '$3');
        //var textonly = cont['mrk'].replace(/(<\/?[^>])(?![^>]*class="ninchar")([^>]*>)/g, '');
        ///var textonly = cont['mrk'].replace(/(<\/?[^>])(?![^>]*class="ninchar")([^>]*>)/ig, '');
        
        var textonly = cont['mrk'].replace(/(<\/?[^>])(?![^>]*class="ninchar")+([^>]*>)/g, '');
        var textonly = cont['mrk']//.replace(/(<\/?[^>])(?![^>]*class="ninchar")+([^>]*>)/g, '');
        
        var del = $('<svg class="del-marking"><use xlink:href="/dist/gui.svg#trash"></use></svg>');
        del.click(function(){
            $.ajax({
                type: "POST",
                url: '/async/deletenote',
                data: '&i=' + cont['idn'],
                success: function() {
                    ninContent.notations();
                }
            });
        });
        if(cont['nte']){
            var row = $('<a><i class="toggle-parent-next"><svg class="arrow"><use class="arrow-use" xlink:href="/dist/gui.svg#arrow-r"></use></svg></i><span class="marking ' + cont['sty'] + '"></span><strong class="marked internal-link" data-content-id="' + fkc + '" data-marking="' + cont['idn'] + '">' + textonly + '</strong></a>');
            row.append(del);
            note.append(row);
            note.append('<p>' + cont['nte'] + '</p>');
        } else {
            var row = $('<a class="internal-link" data-content-id="' + fkc + '" data-marking="' + cont['idn'] + '"><i></i><span class="marking ' + cont['sty'] + '"></span><strong class="marked">' + textonly + '</strong></a>');
            row.append(del);
            note.append(row);            
        }
        return note;
        
  }
    return {
        contentbox: function(id,cont,lyt) {
           return contentbox(id,cont,lyt);
        },
        keywordschapter: function(id,cont) {
           return keywordschapter(id,cont);
        },
        searchresult: function(id,cont,cnt) {
           return searchresult(id,cont,cnt);
        },
        searchresultglob: function(id,cont) {
           return searchresultglobal(id,cont);
        },
        notation: function(id,cont) {
           return notation(id,cont);
        },
    }
}();