var ninGui = function() {
    var search_timeout = null;
    var searchglob_timeout = null;
    var productswitch_timeout1 = null;
    var productswitch_timeout2 = null;
    var resize_timeout = null;
    
    var initHistory = function() {
        window.onpopstate = function(e) {
            if(e.state != null && typeof e.state.idc !== typeof undefined && e.state.idc !== false){
                var content = $('#content');
                content.attr('data-content-id',e.state.idc);
                content.attr('data-keyword','');
                ninContent.load(1);
            }

        };


    }
    var initDock = function() {
        $('.multiswitch > a').each(function(){
            if(!$(this).hasClass('hasfunction')){
                $(this).click(function(){
                    $('.multiswitch ul').toggle();
                });
            }
        });
        $('.bar li').not('.dock-logo').each(function(){
            if(!$(this).hasClass('hasfunction')){
                $(this).click(function(){
                    if($('body').hasClass('dock-open')){
                        if($(this).hasClass('selected')){
                            $('.dock').css('flex','0 0 60px');
                            $('body').removeClass('dock-open').addClass('dock-closed');
                            $('.bar li').removeClass('selected');
                            $('.dock .inner').hide(0);
                            $('.dock .content').hide(0);
                            $.ajax({
                                type: "POST",
                                url: '/async/setsession',
                                data: '&param=lastpanel&val='
                            });

                        } else {
                            $('.bar li').removeClass('selected');
                            selectDock(this);
                        }
                    } else {
                        $('body').removeClass('dock-closed').addClass('dock-open');
                        var dockwidth = $('.dock').attr('data-width');
                        $('.dock .inner').css('width', (dockwidth-75) + 'px');
                        $('.dock').css('flex','0 0 ' + dockwidth + 'px');
                        selectDock(this);
                    }
                });
            }
        });
        if(!$('.dock .handle').hasClass('has-handle')){
            $('.dock .handle').addClass('has-handle')
            $('.dock .handle').draggable({
                axis: "x",
                drag: function(event, ui) {
                    var pos = $(this).position();
                    var newpos = pos['left'];
                    if(pos['left'] < 320){ newpos = 320; ui.position.left = 320; }
                    $('.dock').attr('data-width', (newpos+5))
                    $('.dock .inner').css('width', (newpos-75) + 'px');
                    $('.dock').css('flex','0 0 ' + (newpos+5) + 'px');
                  },
                stop: function(event, ui) {
                    var pos = $(this).position();
                    var newpos = pos['left'];
                    if(pos['left'] < 320) { newpos = 320; ui.position.left = 320; }
                    $('.dock').attr('data-width',(newpos+5))
                    $('.dock .inner').css('width', (newpos-75) + 'px');
                    $('.dock').css('flex','0 0 ' + (newpos+5) + 'px');
                    checkDock();
                    $.ajax({
                        type: "POST",
                        url: '/async/setsession',
                        data: '&param=dockwidth&val=' + newpos
                    });
                },
            });
        }
        $('.dvgui-checkbox > label').on('click', function() {
            var the = $(this);
            var el = $(this).children('span:first-child');
            el.addClass('inc');
            var newone = el.clone(true);  
            el.before(newone);  
            $("." + el.attr("class") + ":last", the).remove();
        }); 
        
        var lastpanel = $('body').attr('data-lastpanel')
        if(lastpanel != 'none'){
            eval('$(\'*[data-dock="' + lastpanel + '"]\').click()');
        }
    }
    
    var initInternalLinks = function() {
        $('.internal-link').each(function(){
            if(!$(this).hasClass('haslink')){
                $(this).addClass('haslink')
                $(this).click(function(){
                    loadContent(this);
                    if($(this).hasClass('searchresult-link')){
                        if($(this).find('.searchresult.selected').length < 1){
                            $('.searchresult').removeClass('selected');
                            $(this).find('.searchresult').first().addClass('selected');
                        }
                    }
                });
            }
        });
    }

    var loadContent = function(that) {
        var content = $('#content');
        var target_id = $(that).attr('data-content-id');
        if(typeof target_id !== typeof undefined && target_id !== false){
            
            var keyword_set = $(that).attr('data-keyword');
            var notation_set = $(that).attr('data-marking');
            
            var target_chapter = $('#chapter' + target_id);
            var target_notations = $('mark[data-id=' + notation_set + ']');
            
            
            content.attr('data-chapter-id',target_id);
            if(typeof notation_set !== typeof undefined && notation_set !== false && notation_set != ''){
                content.attr('data-marking-id',notation_set);
            } else {
                content.attr('data-marking-id','');
            }
            if(typeof keyword_set !== typeof undefined && keyword_set !== false){
                content.attr('data-keyword',keyword_set);                
            } else {
                content.attr('data-keyword','');
            }

            ninContent.print();

            
            if(typeof target_notations !== typeof undefined && target_notations.length){ /* scroll to marking if set */
                var scrolltop = target_notations.offset().top - content.offset().top + content.scrollTop();
                content.animate({
                    scrollTop: scrolltop
                }, 500);
            }else if(typeof target_chapter !== typeof undefined && target_chapter.length){ /* scroll chapter if set allready loaded */
                var scrolltop = target_chapter.offset().top - content.offset().top + content.scrollTop();
                content.animate({
                    scrollTop: scrolltop
                }, 500);
                var idcont = target_chapter.attr('id').replace('chapter','');
                $.ajax({
                    type: "POST",
                    url: '/async/setsession',
                    data: '&param=last_view&val=%2Flink%2F' + idcont
                });
            } else {
                content.attr('data-content-id',target_id);
                ninContent.load();

                var project_title = $(that).attr('data-project-title');
                if(project_title != ''){
                    $('.header-subnav-label').html(project_title);
                }
            }
            if($(that).hasClass('structure-nav')){
                var nextul = $(that).next('ul');
                var arrow = $(that).find('.arrow use');

                if(nextul.is(':visible')){
                    if(arrow.length) arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-d','#arrow-r'));
                    nextul.hide(0);
                } else {
                    if(arrow.length) arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-r','#arrow-d'));
                    nextul.show(0);
                }
            }
            
            
        }
    }
    var initToggleNext = function(that) {
        $('.toggle-next').each(function(){
            if(!$(this).hasClass('hastoggle')){
                $(this).addClass('hastoggle')
                $(this).click(function(){
                    var next = $(this).next();
                    var arrow = $(this).find('.arrow use');
                    if(next.is(':visible')){
                        arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-d','#arrow-r'));
                        next.hide(0);
                    } else {
                        arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-r','#arrow-d'));
                        next.show(0);
                    }               
                });
            }
        });
        $('.toggle-parent-next').each(function(){
            if(!$(this).hasClass('hastoggle')){
                $(this).addClass('hastoggle')
                $(this).click(function(){
                    var next = $(this).parent().next();
                    var arrow = $(this).find('use');
                    if(next.is(':visible')){
                        arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-d','#arrow-r'));
                        next.hide(0);
                    } else {
                        arrow.attr('xlink:href',arrow.attr('xlink:href').replace('#arrow-r','#arrow-d'));
                        next.show(0);
                    }               
                });
            }
        });
    }

    var initSearch = function() {
        $('.dock-search #src').keyup(function(){
            window.clearTimeout(search_timeout);
            search_timeout = window.setTimeout(function(){
                ninContent.search();
            }, 800);
        });
        if($('.dock-search #src').val() != ''){
            ninContent.search();
        }
    }

    var initSearchnav = function() {
        if($.trim($('.dock-search .cont').html()) != ''){
            $('.search-top').addClass('active');
            /*$('.dock-search .search-nav').css('display','inline-block');*/
            var clear = $('.dock-search .search-clear');
            if(!clear.hasClass('has-clear')){
                clear.addClass('has-clear');
                clear.click(function(){
                    $('.dock-search #src').val('');
                    $('.dock-search .cont').html('');
                    $('.search-top').removeClass('active');
                    /*$('.dock-search .search-nav').css('display','none');*/
                });
            }
            
            var next = $('.dock-search .search-nav-next');
            if(!next.hasClass('has-nav-function')){
                next.addClass('has-nav-function');
                next.click(function(){
                    if($('.dock-search .cont').find('.searchresult.selected').length < 1){
                        $('.dock-search .cont').find('.searchresult').first().addClass('selected').parents('li').click();
                    } else {
                        var results = $('.dock-search .cont .searchresult')
                        results.each(function(key, value){
                            if($(this).hasClass('selected')){
                                $(this).removeClass('selected');
                                results.eq(key + 1).addClass('selected').parents('li').click();
                                return false;
                            }
                        });
                    }
                });
            };
            
            var prev = $('.dock-search .search-nav-prev');
            if(!prev.hasClass('has-nav-function')){
                prev.addClass('has-nav-function');
                prev.click(function(){
                    if($('.dock-search .cont').find('.searchresult.selected').length < 1){
                        $('.dock-search .cont').find('.searchresult').last().addClass('selected').parents('li').click();
                    } else {
                        var results = $('.dock-search .cont .searchresult')
                        results.each(function(key, value){
                            if($(this).hasClass('selected')){
                                $(this).removeClass('selected');
                                results.eq(key - 1).addClass('selected').parents('li').click();
                                return false;
                            }
                        });
                    }
/*
                    var prv = null;
                    var found = 0;
                    $('.dock-search .cont .searchresult').each(function(){
                        if($(this).hasClass('selected')){
                            $(this).removeClass('selected');
                            if(prv !== null){
                                $(prv).addClass('selected').parents('li').click();
                                found = 1;
                                return false;
                            }
                        }
                        prv = $(this);
                    });
                    if(found < 1){
                        $('.dock-search .cont').find('.searchresult').last().addClass('selected').parents('li').click();
                    }*/
                });
            }
        } else {
            $('.dock-search .cont').html('');
            $('.search-top').removeClass('active');
            /*$('.dock-search .search-nav').css('display','none');*/
        }
    }
    
    var initFilter = function() {
        $('.dock-notations .notations-filter a').each(function(){
            if(!$(this).hasClass('has-filter')){
                $(this).addClass('has-filter');
                $(this).click(function(){
                    var color = $(this).attr('data-type');
                    if($(this).hasClass('selected')){
                        $(this).removeClass('selected');
                         $('.dock-notations .cont .marking.' + color).parent().hide();
                    } else {
                        $(this).addClass('selected');
                         $('.dock-notations .cont .marking.' + color).parent().show();
                    }
                    $('.notations-result-list > li').each(function(){
                        var that = $(this);
                        if($(this).find('ul li a:visible').length > 0){
                            $(that).children('a').show();
                        } else {
                            $(that).children('a').hide();
                        }
                    });
                });
            }     
        });
    }
    
    var initGlobalSearch = function() {
        $('#search-global-button').on('click', function(){
            var globsrc = $('#search-global');
            if(globsrc.hasClass('open')){
                globsrc.removeClass('open');
            } else {
                globsrc.addClass('open');
                var srcfield = $('#srcglob');
                srcfield.focus();
                if(srcfield.val() != ''){
                    srcfield.keyup();
                }
            }
        });
        $('.search-global-close').on('click', function(){
            var globsrc = $('#search-global')
            globsrc.removeClass('open');
        });
        
        $('#srcglob').keyup(function(){
            window.clearTimeout(searchglob_timeout);
            searchglob_timeout = window.setTimeout(function(){
                ninContent.searchglobal();
            }, 800);
        });
    }
    
    var selectDock = function(that) {
        $(that).addClass('selected');
        $('.dock .inner').show(0);
        var type = $(that).attr('data-dock');
        $('.dock .content').hide(0);
        $('.dock .dock-' + type).show('fast', function(){
            // dockspecific
            switch(type){
               case 'notation':
               break;
               case 'maps':
                   console.log('special maps');
                   // open first map
                   $('.dock-maps .map-preview').first().click();
               break;
            }
            checkDock();
        });
        $.ajax({
            type: "POST",
            url: '/async/setsession',
            data: '&param=lastpanel&val=' + type
        });
    }

    var initProductswitch = function() {
        $('body.product .product-title').each(function(){
            if(!$(this).hasClass('hasfunction')){
                $(this).addClass('hasfunction');
                var target = $(this).find('.productswitch');
                var to_nr = $(this).attr('data-nr');
                $(this).on('mouseover', function(){
                    eval("window.clearTimeout(productswitch_timeout" + to_nr + ")");
                    $(target).slideDown(300);
                }).on('mouseout', function(){
                    eval("productswitch_timeout" + to_nr + " = window.setTimeout(function(){ $(target).slideUp(300);}, 300)");
                });
                /*
                $(target).on('mouseover', function(){
                    window.clearTimeout(productswitch_timeout);
                }).on('mouseout', function(){
                    productswitch_timeout = window.setTimeout(function(){
                        $(target).slideUp(300);
                    }, 300);
                });*/
            }
        });
    }


    var initMobileNav = function() {
        $('body.home .mob-menu').each(function(){
            if(!$(this).hasClass('hasfunction')){
                $(this).addClass('hasfunction');
                $(this).on('click', function(){
                    if($('body').hasClass('mob-nav-open')){
                        $('body').removeClass('mob-nav-open');
                        $(this).find('.hamburger').removeClass('open');
                    } else {
                        $('body').addClass('mob-nav-open');
                        $(this).find('.hamburger').addClass('open');
                    }
                });
            }
        });
    }
    var initTooltip = function(){
        $('#dv_tooltip').remove();
        $('body').append('<div id="dv_tooltip"><div></div></div>');
        $('.dv-tt').each(function(){
            if(!$(this).hasClass('has-tooltip')){
                $(this).mouseenter(function(){
                    var attr = $(this).attr('data-tooltip');
                    if (typeof attr !== 'undefined' && attr !== false) {
                        var position = $(this).offset();
                        $('#dv_tooltip div').html(attr);
                        var top = position.top + $(this).outerHeight();
                        var left = position.left -($('#dv_tooltip').outerWidth()/2 - $(this).outerWidth()/2);
                        if(left < 0){
                            left = 0;
                            $('#dv_tooltip').css('background-position', ($(this).outerWidth()/2) + 'px top');
                        }
                        $('#dv_tooltip').css('top',top).css('left',left).fadeIn(0.7);
                    }
                });
                $(this).mousemove(function(e){ 
                    $('#dv_tooltip').css('left', e.pageX + 10).css('top', e.pageY + 10).css('display', 'block');
                });
                $(this).mouseleave(function(){
                    $('#dv_tooltip').fadeOut(0, function(){
                        $('#dv_tooltip div').html('');
                    });
                });
                $(this).addClass('has-tooltip');
            }
        });
    }
    var initResize = function(){
        $( window ).resize(function() {
            window.clearTimeout(resize_timeout);
            resize_timeout = window.setTimeout(function(){
                checkDock();
            }, 400);
        });
    }
    var checkDock = function(){
        var w = $('main').width()
        if(w < 560){
            $('body').addClass('dock-over');
        } else if(w > 960){
            $('body').removeClass('dock-over');
        }
    }
    
    var removeNotification = function(){
        $('.rmNotification').each(function(){
            $(this).click(function(){
                var popup = $(this).parent().parent();
                if (popup.siblings().length < 1){
                    $('#fullscreen').remove();
                } else {
                    popup.remove();
                }
            });
        });
    }
    
    return {
        init: function() {
            initDock();
            initProductswitch();
            initSearch();
            initGlobalSearch();
            initResize();
            initInternalLinks();
            initHistory();
            initFilter();
        },
        initAfterSync: function() {
            initTooltip();
            initInternalLinks();
            initToggleNext();
            initFilter();
        },
        initSearchnav: function() {
            initSearchnav();
        },
        initHome: function() {
            initGlobalSearch();
            initMobileNav();
            removeNotification();
        },
        initTooltip: function() {
            initTooltip();
        },
    }
}();