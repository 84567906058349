var ninNotation = function() {
    var dv_separator = '#dv#';
    var tooltip_over = false;
    var highlight_over = false;
    var hide_timeout = false;
    var write_timeout = false;

    var tt          = null;
    var tt_inner    = null;
    var tt_text     = null;
    var tt_del      = null;

    // get markings relevant to the current content
    var getMarkings = function() {
        $('.highlightable').each(function(){
            var that = $(this);
            var current_paragraph = $(this).attr('data-content-id');
            
            $.ajax({
                type: "POST",
                url: '/async/getmarkings',
                data: '&p=' + current_paragraph,
                success: function(content) {
                    var json_data = $.parseJSON(content);
                    
                    $(that).find('.content-inner').each(function(){
                        // refresh markings in content
                        var that2 = $(this);
                        var current_paragraph2 = $(this).attr('data-content-id');
                        
                        if(current_paragraph2 in json_data){
                            removeMarkings(that2);
                            addMarkings(that2,json_data[current_paragraph2]);
                        }
                    });
                    
                    initMarkingsTooltips();
                    initTexthighlighter();
                    ninContent.afterhighlighting();
                }
            });
        });
    }
    
    var addMarkings = function(container, data) {
       $(container).unmark({
            done: function() {
                for(var i=0; i < data.length; i++){
                    //console.log(data[i]['selected']);
                    //console.log(data[i]['selected_raw']);
                    //console.log('##############################################');
                    var cnt = 0;
                    var returned = 0;
                    var options = {
                        "separateWordSearch": false,
                        "ignoreJoiners":false,
                        "className":'highlighted ' + data[i]['style'],
                        "acrossElements": true,
                        //"debug":true,
                        //"log":console,
                        each: function(node){
                            node.setAttribute("data-id", data[i]['id_notations']);
                            node.setAttribute("data-note", data[i]['note']);
                        },
                        
                        filter: function(node, term, totalCounter, wordCounter) {
                            var matches = node.textContent.match(term);
                            /* if search string is substring of node or counter is 0 => count up*/
                            if(wordCounter === 0 || matches){
                                cnt++;
                            }

                            
                            if(parseInt(data[i]['position']) === cnt){
                                return true;
                            }
                            if(parseInt(data[i]['position']) < cnt){
                                return false;
                            }
                        }
                    };
                    $(container).mark(data[i]['selected'], options);
                }
                /*
                for(var i=0; i < data.length; i++){
                    var cnt = 0;
                    var options = {
                        "separateWordSearch": false,
                        "ignoreJoiners":true,
                        "className":'highlighted ' + data[i]['style'],
                        "acrossElements": true,
                        /*"debug":true,
                        "log":console,*//*
                        each: function(node){
                            node.setAttribute("data-id", data[i]['id_notations']);
                            node.setAttribute("data-note", data[i]['note']);
                        },
                        filter: function() {
                            cnt++;
                            if(data[i]['position'] == cnt)
                              return true;
                        }
                    };

                    $(container).mark(data[i]['selected'], options);
                }
                */
            }
        });
        
    }

    var escapeRegExp = function(string) {
      var newstring = string;
      newstring = newstring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
      newstring = newstring.replace(/></g, '>\\s*<'); // $& means the whole matched string      
      return newstring
    }
    
    var removeMarkings = function(container) {
        $(container).find('.highlighted').each(function(){
            var text = $(this).text();
            
            $(this).after(text);
            $(this).remove();
        });
        $(container).find('.is-highlightable').removeClass('is-highlightable');
    }

    var initMarkingsTooltips = function() {
        $('#note-tooltip').remove();
        var tt          = $('<div id="note-tooltip" class=""></div>')
        var tt_inner    = $('<div></div>');
        var tt_text     = $('<textarea></textarea>');
        var tt_del      = $('<a><svg class="note-tooltip-icon"><use xlink:href="/dist/gui.svg#trash"></use></svg></a>');
        //var tt_pin      = $('<a><svg class="note-tooltip-icon pin dv-tt" data-tooltip="Festpinnen"><use xlink:href="/dist/gui.svg#pin"></use></svg></a>');
        var tt_pin      = $('<a><svg class="note-tooltip-icon pin"><use xlink:href="/dist/gui.svg#pin"></use></svg></a>');
        tt_inner.append(tt_text);
        tt_inner.append(tt_del);
        tt_inner.append(tt_pin);
        tt.append(tt_inner);
        tt.appendTo('body');
        tt.mouseenter(function(){
            tooltip_over = true;
        });
        tt.mouseleave(function(){
            if(!tt.hasClass('is-pinned')){
                tooltip_over = false;
                if(!highlight_over){
                    tt.fadeOut(0, function(){
                        tt_text.val('');
                    });
                }
            }
        });

        
        $('.highlighted').each(function(){
            if(!$(this).hasClass('has-tooltip')){
                $(this).mouseenter(function(e){
                    if(!tt.hasClass('is-pinned')){
                        highlight_over = true;
                        window.clearTimeout(hide_timeout);
                        var note = $(this).attr('data-note');
                        var id = $(this).attr('data-id');
                        var that = $(this);
                        if (typeof id !== 'undefined' && id !== false && id != '') {
                            tt_text.val(note);
                            tt_text.unbind('keyup');
                            tt_text.keyup(function(){
                                $(that).attr('data-note', tt_text.val())
                                window.clearTimeout(write_timeout);
                                write_timeout = window.setTimeout(function(){
                                    $.ajax({
                                        type: "POST",
                                        url: '/async/writenote',
                                        data: '&i=' + id + '&n=' + tt_text.val(),
                                        success: function(){
                                            ninContent.notations();
                                        }
                                    });
                                },700);
                            });
                            tt_del.unbind('click');
                            tt_del.click(function(){
                                $.ajax({
                                    type: "POST",
                                    url: '/async/deletenote',
                                    data: '&i=' + id,
                                    success: function() {
                                        ninContent.notations();
                                        $('.highlighted[data-id="' + id + '"]').unmark();
                                        // hide tooltip
                                        tt.fadeOut(0, function(){
                                            tt_text.val('');
                                            tt_text.unbind('keyup');
                                            tt_del.unbind('click');
                                        });
                                    }
                                });
                            });
                            tt_pin.unbind('click');
                            tt_pin.click(function(){
                                tt.toggleClass('is-pinned');
                            });
                            var top = e.pageY + 5;
                            var left = e.pageX + 5;
                            if(left < 0){
                                left = 0;
                                tt.css('background-position', ($(this).outerWidth()/2) + 'px top');
                            }
                            tt.css('top',top).css('left',left).fadeIn(0.7);
                        }
                    } 
                });
                /*
                $(this).mousemove(function(e){ 
                    if($('#note-tooltip div').html() != ''){
                        $('#note-tooltip').css('left', e.pageX + 10).css('top', e.pageY + 10).css('display', 'block');
                    }
                });*/
                $(this).mouseleave(function(){
                    if(!tt.hasClass('is-pinned')){
                        highlight_over = false;
                        hide_timeout = window.setTimeout(function(){
                            if(!tooltip_over){
                                // hide tooltip
                                tt.fadeOut(0, function(){
                                    tt_text.val('');
                                    tt_text.unbind('keyup');
                                    tt_del.unbind('click');
                                    tt_pin.unbind('click');
                                });
                            }
                        }, 500);
                    }
                });
                $(this).addClass('has-tooltip');
            }
        });
    }
    
    // init text highlight functionality
    var initTexthighlighter = function() {
        
        // add marker selection functionality
        $('.highlighter').each(function(){
            if(!$(this).hasClass('has-highlighter')){
                $(this).addClass('has-highlighter');
                $(this).click(function(){
                    if($(this).hasClass('selected')){
                        $(this).removeClass('selected');
                    } else {
                        $('.highlighter').removeClass('selected');
                        $(this).addClass('selected');
                    }
                });
            }
        });
        
        // add marker listeners
        $('.highlightable .content-inner').each(function(){
            if(!$(this).hasClass('is-highlightable') && !$(this).hasClass('is-toc')){
                $(this).addClass('is-highlightable');
                $(this).on('mouseup touchend touchcancel', function(){
                    console.log('fire ...');
                //$(this).on('mouseup', function(){
                    var text = '';
                    var sel = '';
                    
                    if (window.getSelection) {
                        sel = window.getSelection()
                        rng = sel.getRangeAt(0);
                        var cpy = rng.cloneContents();
                        var box = document.createElement('var');
                        box.appendChild(cpy);
                        text = box.innerHTML;
                        box.remove();
                    } else if (document.selection && document.selection.type != "Control") {
                        sel = document.selection.createRange();
                        text = document.selection.createRange().htmlText;
                    }
                    if(text != '' && $('.highlighter.selected').length > 0 && sel.rangeCount > 0){
                        var occurrence_postition = 1;
                        var range = sel.getRangeAt(0);
                        var dv_split = document.createTextNode(dv_separator);
                        range.insertNode(dv_split);
                        var parts = $(this).html().split(dv_separator);
                        var regex = new RegExp(text,"g");
                        
                        var occurrence_postition = (parts[0].match(regex) || []).length + 1;
                        $(this).html($(this).html().replace(dv_separator,''));

                        var current_paragraph = $(this).attr('data-content-id');
                        var current_style = $('.highlighter.selected').attr('data-highlight-style');
                        if(parseInt(current_paragraph) > 0){
                            $.ajax({
                                type: "POST",
                                url: '/async/sethighlight',
                                data: '&p=' + current_paragraph + '&o=' + occurrence_postition + '&s=' + current_style + '&t=' + encodeURI(text),
                                success: function(content) {
                                    getMarkings();
                                    ninContent.notations();
                                }
                            });
                        } 
                    } else {
                        console.log('No Marker selected');
                    }
                });
            }
        });
    }
    
    return {
        init: function() {
            /*initTexthighlighter();*/
        },
        highlighter: function() {
            initTexthighlighter();
        },
        markings: function() {
            getMarkings();
        },
    }
}();